import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";

export default () => {

  return (
    <Layout>

    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-filmmusik' className="closeX"><span>X</span></Link>
      <h2>Filme von Blender</h2>
    </div>
    <div style={{ 'marginTop': '20px' }}>
      Auf dieser Seite finden Sie Links zu den unter CC-Lizenz veröffentlichten Filmen von <a href="https://blender.org">Blender</a>, die aufgrund der freien Lizenz für einen kreativen Unterricht zum Thema Film geeignet sind.
    </div>
    <div id="content">
      <ul style={{ 'listStylePosition': 'inside' }}>
        <li><a href="#ElephantsDream">Elephants Dream (2006)</a></li>
        <li><a href="#BigBuckBunny">Big Buck Bunny (2008)</a></li>
        <li><a href="#Sintel">Sintel (2010)</a></li>
        <li><a href="#TearsOfSteel">Tears of Steel (2012)</a></li>
        <li><a href="#Caminandes12">Caminandes 1 & 2: Llama Drama und Gran Dillama (2013)</a></li>
        <li><a href="#CosmosLaundromat">Cosmos Laundromat (2015)</a></li>
        <li><a href="#GlassHalf">Glass Half (2015)</a></li>
        <li><a href="#Caminandes3">Caminandes: Llamigos (2016)</a></li>
        <li><a href="#Agent327OperationBarbershop">Agent 327: Operation Barbershop (2017)</a></li>
        <li><a href="#TheDailyDweebs">The Daily Dweebs (2017)</a></li>
        <li><a href="#Hero">Hero (2018)</a></li>
        <li><a href="#Spring">Spring (2019)</a></li>
        <li><a href="#CoffeeRun">Coffee Run (2020)</a></li>
      </ul>
    </div>
    <h2 style={{ 'marginBottom': '20px' }}>Links und Kurzbeschreibungen</h2>
    <div className="gridBox">
      <div id="ElephantsDream" className="gridFraction">
        <p>
          <a href="https://orange.blender.org/">Elephants Dream (2006)</a><br />
          <a href="https://www.youtube.com/watch?v=TLkA0RELQ1g">Elephants Dream auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
          <b>Elephants Dream</b> war der erste unter CC-BY-Lizenz veröffentlichte Film der Blender Foundation, der unter dem Projketnamen Orange entwickelt worden ist. In dem Film führt Proog den jungen Emo durch eine riesige Maschine. Der unheimliche und selbstbewusst wirkende Proog leidet allerdings unter Wahnvorstellungen, während Emo die Maschine mächtig Angst macht. Die Handlung führt durch verschiedenste Bereiche der Maschine, die Proogs Wahnvorstellungen zu materialisieren scheinen und auf Emo wie ein Alptraum wirken.
      </div>
      <div id="BigBuckBunny" className="gridFraction">
        <p>
          <a href="https://peach.blender.org/">Big Buck Bunny (2008)</a><br />
          <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ">Big Buck Bunny auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Big Buck Bunny</b> war der zweite unter CC-BY-Lizenz veröffentlichte Film der Blender Foundation, der unter dem Projketnamen Peach entwickelt worden ist. Hauptfigur des Films ist ein dickes, großes und liebenswertes Kaninchen (Big Buck Bunny). Seine Gegenspieler sind das Flughörnchen Frank, das Eich hörnchen Rinky sowie das Chinchilla Gamera (ein Chinchilla ist eine Woll maus, die in Südamerika beheimatet und aufgrund des schönen Fells schon beinahe ausgerottet worden ist). Diese drei Nagetiere töten aus Gemeinheit andere Tiere und ärgern Big Buck Bunny, woraufhin der gutmütige Hase zum Rächer wird.<br />
        Der Big-Buck-Bunny-Film ist Gegenstand des OpenBooks:<a href="/filmmusik">Filmmusik</a>
      </div>
      <div id="Sintel" className="gridFraction">
        <p>
          <a href="https://durian.blender.org/">Sintel (2010)</a><br />
          <a href="https://www.youtube.com/watch?v=eRsGyueVLvQ&t=6s">Sintel auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Sintel</b> war der dritte unter CC-BY-Lizenz veröffentlichte Film der Blender Foundation, der unter dem Projketnamen Durian entwickelt worden ist. Hauptfigur des Films ist eine junge Frau, die einen kleinen verwundeten Drachen findet und gesund pflegt. Sie nennt den Drachen Scales und freundet sich mit ihm an. Nachdem der kleine Drachen von einem mächtigen Drachen geraubt wird, macht sich Sintel auf den Weg, ihn zu suchen. Die Reise dauert lange und auf einem verscheiten Berg wird Sintel angegriffen. Sintel besiegt den Angreifer und flüchtet sich mit letzter Kraft in das Zelt eines Schamanen. Der baut Sintel wieder auf und weist ihm den Weg zu einer Drachenhöhle, in der Sintel seinen kleinen Drachen zu entdecken glaubt. Doch die Mutter des kleinen Drachen greift sintel an und in einem Kampf tötet Sintel den Mutterdrachen. Nachdem der Drachen tot ist, bemerkt Sintel, dass es sich bei dem Mutterdrachen um den kleine Scales handelte, der in der Ziwschenzeit zu einem großen Drachen herangewachsen war. Die letzte Szene zeigt die trauernde Kriegerin Sintel, die ihren Kampfgürtel bzw. ihre Waffen fallen lässt...<br />
        Der Sintel-Film ist Gegenstand des OpenBooks:<a href="/filmmusik">Filmmusik</a>
      </div>
      <div id="TearsOfSteel" className="gridFraction">
        <p>
          <a href="https://mango.blender.org/">Tears of Steel (2012)</a><br />
          <a href="https://www.youtube.com/watch?v=R6MlUcmOul8&t=603s">Tears of Steel auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Tears of Steel</b> wurde unter dem Projketnamen Mango entwickelt und 2012 unter CC-Lizenz veröffentlicht. Es ist ein Science-Fiction-Film, der in einer fernen Zukunft spielt und in dem eine Gruppe von Kämpfern und Wissenschaftlern versucht, die Welt vor zerstörerischen Robotern zu retten. Die echten Schauspieler*innen wurden mittels Chroma Keying und Rotoskopie in die Szenerien projeziert.
      </div>
      <div id="Caminandes12" className="gridFraction">
        <p>
          <a href="http://www.caminandes.com/">Caminandes 1-2 (2013)</a><br />
          <a href="https://www.youtube.com/watch?v=JOhiWY7XmoY">Llama Drama (1) auf YouTube</a><br />
          <a href="https://www.youtube.com/watch?v=Z4C82eyhwgU">Gran Dillama (2) auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Caminandes 1/2: Llama Drama und Gran Dillama (2013)</b> sind Kurzfilme, in denen das Lama Koro jeweils eine Aufgabe zu bewältigen hat (Straße überqueren, Elektrozaun überwinden). Dabei bekommt Koro ganz unverhoffte Hilfe von Armadillo...<br />
         Der Camindandes-Filme sind Gegenstand des OpenBooks:<a href="/filmmusik">Filmmusik</a>
      </div>
      <div id="CosmosLaundromat" className="gridFraction">
        <p>
          <a href="https://gooseberry.blender.org/">Cosmos Laundromat (2015)</a><br />
          <a href="https://www.youtube.com/watch?v=Y-rmzh0PI3c">Cosmos Laundromat auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Cosmos Laundromat</b> ist der erste Teil des Gooseberry Open Movie Projects. In diesem Teil geht es um ein Schaf mit dem Namen Franck, das auf einer Insel sich an einem Baum zu erhängen versucht. Doch der Ast bricht ab, und mit Strick und Ast um den Hals geht Franck zu einer Klippe. In diesem Moment erscheint Victor, der auf Franck zu geht und sich darum bemüht, Franck vom Selbstmord abzuhalten. Schließlich hält Franck inne und gewährt Victor ein wenig Zeit. Victor legt Franck daraufhin ein Halsband um, setzt sich Kopfhörer auf, legt eine Kassette in seinen Kassettenrecorder und hört Musik. Ab da nimmt der Film eine ganz unerwartete Wendung, die allerdings sehr seltsam ist, so dass man sich schon auf eine Fortsetzung freuen darf...
      </div>
      <div id="GlassHalf" className="gridFraction">
        <p>
          <a href="https://cloud.blender.org/p/glass-half/">Glass Half (2015)</a><br />
          <a href="https://www.youtube.com/watch?v=lqiN98z6Dak">Glass Half auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Glass Half</b> ist ein Cartoon-Film von nur drei Minuten Länge, in dem sich zwei Kunstkenner, eine Frau und ein Mann, in einer Galerie über die ausgestellten Kunstwerke leidenschaftlich streiten. Die beiden Personen sind aus der Perspektive der Kunstwerke zu sehen. Ein Schlichtungsversuch eines Dritten schlagen fehl, jedoch entdeckt die Frau am Ende ein Bild, was sie dem mann zeigt, für das sich beide begeistern können.
      </div>
      <div id="Caminandes3" className="gridFraction">
        <p>
          <a href="http://www.caminandes.com/">Caminades 3: Llamigos (2016)</a><br />
          <a href="https://www.youtube.com/watch?v=lqiN98z6Dak">Llamigos auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Caminades 3: Llamigos</b> ist der dritte Film aus der Caminandes-Reihe, in dem das Lama Koro mit Oti, einem Pinguin, um eine schmackvolle Beere kämpft. Koro verliert den Kampf, ist jedoch am Ende der Schlacht der große Gewinner...
      </div>
      <div id="gent327OperationBarbershop" className="gridFraction">
        <p>
          <a href="https://agent327.com/">Agent 327: Operation Barbershop (2017)</a><br />
          <a href="https://www.youtube.com/watch?v=mN0zPOpADL4">Operation Barbershop auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Agent 327: Operation Barbershop</b> ist ein vierminütiger Kurzfilm, der die Comicserie <i>Agent 327</i> des niederländischen Zeichners Martin Lodewijk zur Vorlage hat. In diesem Comic geht es um den Agenten 327, mit bürgerlichen Namen Otto Otto Eisenbrot, der Abeunteuer besteht, die eine Persiflage auf die 007-James-Bond-Agentenfilme sind. Der vierminütige Trailer ist ein Werbefilm, um Investoren für einen Film in Spielfilmlänge zu gewinnen.        
      </div>
      <div id="TheDailyDweebs" className="gridFraction">
        <p>
          <a href="https://www.dailydweebs.com/">The Daily Dweebs (2017)</a><br />
          <a href="https://www.youtube.com/watch?v=ODoFNi5BgOY">The Daily Dweebs auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>The Daily Dweebs</b> ist ein knapp eine Minute langer Kurzfilm über Süßigkeiten, die zu einem Streit zwischen einer gierigen Schnecke und einem Huhn führen.        
      </div>
      <div id="Hero" className="gridFraction">
        <p>
          <a href="https://cloud.blender.org/p/hero/">Hero (2018)</a><br />
          <a href="https://www.youtube.com/watch?v=AHYGs9B5atQ">Hero auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Hero</b> ist bisher ein dreieinhalbminütiger Action-Cartoon, indem es um einen Erngieobjekt geht, das Raketen antreiben und grüne Landschaften entstehen lassen kann. Und natürlich gibt es zwei Parteien, die sich um dieses Energieobjekt streiten, wobei man nicht weiß, ob Hero der Gute oder der Böse ist. Hero jedenfalls möchte das Energieobjekt für den technischen Fortschritt verwenden, sein Gegner für das Erblühen und den Erhalt der Natur...  
      </div>
      <div id="Spring" className="gridFraction">
        <p>
          <a href="https://cloud.blender.org/p/spring/">Spring (2019)</a><br />
          <a href="https://www.youtube.com/watch?v=WhWc3b3KhnY">Spring auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Spring</b> ist wie Sintel ein Fantasy-Film. Der Film erzählt die Geschichte eines Hirtenmädchens und ihres kleinen Hundes, die atemberaubend und wundersam ist. So wundersam, dass man sie nur schwer nacherzählen kann, sondern einfach gesehen haben muss...
      </div>
      <div id="CoffeeRun" className="gridFraction">
        <p>
          <a href="https://cloud.blender.org/p/spring/">Coffee Run (2020)</a><br />
          <a href="https://www.youtube.com/watch?v=PVGeM40dABA">Coffee Run auf YouTube</a><br />
          <a href="#content">Zurück zum Inhaltsverzeichnis</a>
        </p>
      </div>
      <div className="gridFraction">
        <b>Coffee Run</b> ist ein dreiminütiger Cartoon-Film, in dem eine Frau Kaffee trinkt, der wie ein super Energy-Drinkt wirkt und sie mit Superkräften ausgestattet durch eine Stadt jagen lässt. Wei eine Schleife beginnt der zweite und dritte Lauf in einer Kaffeebar, wo ein Mann einer Frau einen Heiratsantrag macht. Vor dem vierten Lauf beobachtet die Frau den Heiratsantra, verzichtet auf die Kaffee-Droge und sitzt zum Schluss alleine in einem Zimmer mit einem Berg von Kaffeebechern um sich herum. Ein Film, der zum Nachdenken anregt.  
      </div>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Sintel Projekt</title>
      <meta name="description" content="Hier finden Sie Vertonungen der Eröffnungssequenz des Films Sintel von Blender zum einem OpenBook (OER) Filmmusik von Ulrich Kaiser." />
      <meta name="keywords" content="Filmmusik, Sintel, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

